import { createAction, props } from '@ngrx/store';

import { ICheckinOut } from '../../core/models/api/generics/checkin-out/checkin-out.model';

export const loadRequest = createAction(
  '[Daily Closure Widgets] Load Request',
  props<{ propertyIds: number[]; date: string }>(),
);
export const loadSuccess = createAction(
  '[Daily Closure Widgets] Load Success',
  props<{ check_in_out: ICheckinOut }>(),
);
export const loadFailure = createAction(
  '[Daily Closure Widgets] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Daily Closure Widgets] Reset State');
