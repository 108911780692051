import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ICheckinOut } from '../../core/models/api/generics/checkin-out/checkin-out.model';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CheckinOutService } from '../../core/services/checkin-out.service';

import * as fromActions from './actions';

@Injectable()
export class DailyClosureWidgetsStoreEffects {
  constructor(
    private dataService: CheckinOutService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyIds, date }) =>
        this.dataService.loadCheckinOut(propertyIds, date).pipe(
          map(({ data }: IResponseSuccess<ICheckinOut[]>) => {
            return fromActions.loadSuccess({ check_in_out: data[0] });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
