import { Action, createReducer, on } from '@ngrx/store';
import { sumBy } from 'lodash';

import { PropertiesPaymentsTotals } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { payments, totals }) =>
    fromState.featureAdapter.setAll(payments || [], {
      ...state,
      totals: { ...totals, total: getTotalOfTotals(totals) },
      loading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),
);

export function propertiesPaymentsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function getTotalOfTotals(totals: PropertiesPaymentsTotals) {
  const records = Object.values(totals || {});

  return {
    balance: sumBy(records, 'balance'),
    in: sumBy(records, 'in'),
    out: sumBy(records, 'out'),
  };
}
