import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import {
  ProductionEntities,
  ProductionTreatment,
  StatsResponse,
} from '../../models';
import { StatsProductionService, StatsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ProductionTreatmentsStoreEffects {
  constructor(
    private dataService: StatsProductionService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ data }) =>
        this.dataService.load(data).pipe(
          map((response: IResponseSuccess<StatsResponse>) => {
            return fromActions.loadSuccess({
              data: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
