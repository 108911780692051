import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';

import { DailyClosureWidgetsStoreEffects } from './effects';
import {
  dailyClosureWidgetsReducerFactory,
  DailyClosureWidgetsStoreReducer,
} from './reducer';
import { State } from './state';

const REDUCER_TOKEN = new InjectionToken<ActionReducer<Map<string, State>>>(
  'Daily Closure Widgets State Reducer',
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('dailyClosureWidgets', REDUCER_TOKEN),
    EffectsModule.forFeature([DailyClosureWidgetsStoreEffects]),
  ],
  providers: [
    DailyClosureWidgetsStoreReducer,
    {
      provide: REDUCER_TOKEN,
      deps: [DailyClosureWidgetsStoreReducer],
      useFactory: dailyClosureWidgetsReducerFactory,
    },
  ],
})
export class DailyClosureWidgetsStoreModule {}
