import { createAction, props } from '@ngrx/store';

import {
  ProductionEntities,
  Stats,
  StatsRequest,
  StatsResponse,
  StatsTotals,
} from '../../models';

export const loadRequest = createAction(
  '[Production Treatments] Load Request',
  props<{ data: Partial<StatsRequest> }>(),
);
export const loadSuccess = createAction(
  '[Production Treatments] Load Success',
  props<{
    data: StatsResponse;
  }>(),
);
export const loadFailure = createAction(
  '[Production Treatments] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Production Treatments] Reset State');
