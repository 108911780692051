import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>(
  'propertiesDocumentsStore',
);

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);
