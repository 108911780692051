import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('productionTreatments');

export const selectAllItems = createSelector(
  selectState,
  (state: State) => state.rows,
);

export const selectEntities = createSelector(
  selectState,
  ({ rows, totals }) => ({ rows, totals }),
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);
