import { StatsHeaderWidget } from '../../models';

export interface State {
  widgets: StatsHeaderWidget[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  widgets: null,
  loading: false,
  error: null,
};
