import { createAction, props } from '@ngrx/store';

import { ProductionReservations } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Production Reservations] Load Request',
  props<{ searchParams: SearchParams }>(),
);
export const loadSuccess = createAction(
  '[Production Reservations] Load Success',
  props<{ entities: ProductionReservations }>(),
);
export const loadFailure = createAction(
  '[Production Reservations] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Production Reservations] Reset State');
