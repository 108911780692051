import { createAction, props } from '@ngrx/store';

import { PropertyDocument } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Properties Documents] Load Request',
  props<{ searchParams: SearchParams }>(),
);
export const loadSuccess = createAction(
  '[Properties Documents] Load Success',
  props<{ documents: PropertyDocument[] }>(),
);
export const loadFailure = createAction(
  '[Properties Documents] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Documents] Reset State');
