import { createAction, props } from '@ngrx/store';

import { PropertiesPaymentsTotals, PropertyPayment } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Properties Payments] Load Request',
  props<{ searchParams: SearchParams }>(),
);
export const loadSuccess = createAction(
  '[Properties Payments] Load Success',
  props<{ payments: PropertyPayment[]; totals: PropertiesPaymentsTotals }>(),
);
export const loadFailure = createAction(
  '[Properties Payments] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Payments] Reset State');
