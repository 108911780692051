import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PropertyDocument } from '../../models';

export const featureAdapter: EntityAdapter<PropertyDocument> = createEntityAdapter<
  PropertyDocument
>({
  selectId: (model) => model.reference_id,
});

export interface State extends EntityState<PropertyDocument> {
  loading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  loading: false,
  error: null,
});
