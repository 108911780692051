import { createAction, props } from '@ngrx/store';

import { ProductionOccupancy, StatsRequest } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Production Occupancy] Load Request',
  props<{ data: Partial<StatsRequest> }>(),
);
export const loadSuccess = createAction(
  '[Production Occupancy] Load Success',
  props<{ occupancy: ProductionOccupancy }>(),
);
export const loadFailure = createAction(
  '[Production Occupancy] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Production Occupancy] Reset State');
