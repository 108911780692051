import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';
import moment from 'moment';

import { ICheckinOut } from '../../core/models/api/generics/checkin-out/checkin-out.model';
import { StatsHeaderWidget } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

const KEYS = ['all_accommodations', 'arrival', 'departure', 'in_house'];

@Injectable()
export class DailyClosureWidgetsStoreReducer {
  constructor() {}

  createReducer() {
    return createReducer(
      fromState.initialState,

      on(fromActions.loadRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
      })),
      on(fromActions.loadSuccess, (state, { check_in_out }) => ({
        ...state,
        widgets: this.getWidgets(check_in_out),
        loading: false,
        error: null,
      })),
      on(fromActions.loadFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
      })),

      on(fromActions.resetState, () => ({
        ...fromState.initialState,
      })),
    );
  }

  private getWidgets(check_in_out: ICheckinOut): StatsHeaderWidget[] {
    return KEYS.map((key) => {
      return {
        title: { key: 'daily_closure_widgets_titles.' + key },
        value: check_in_out[key] || 0,
        count: moment().format('DD/MM/YYYY'),
      };
    });
  }
}

export function dailyClosureWidgetsReducerFactory(
  reducer: DailyClosureWidgetsStoreReducer,
) {
  return reducer.createReducer();
}
