import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PropertiesPaymentsStoreEffects } from './effects';
import { propertiesPaymentsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'propertiesPaymentsStore',
      propertiesPaymentsReducer,
    ),
    EffectsModule.forFeature([PropertiesPaymentsStoreEffects]),
  ],
})
export class PropertiesPaymentsStoreModule {}
