import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProductionReservationsStoreEffects } from './effects';
import { productionReservationsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'productionReservations',
      productionReservationsReducer,
    ),
    EffectsModule.forFeature([ProductionReservationsStoreEffects]),
  ],
})
export class ProductionReservationsStoreModule {}
