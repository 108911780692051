import { ProductionOccupancy } from '../../models';

export interface State {
  occupancy: ProductionOccupancy;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  occupancy: null,
  loading: false,
  error: null,
};
