import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { PropertiesPaymentsTotals, PropertyPayment } from '../../models';
import { PropertiesPaymentsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class PropertiesPaymentsStoreEffects {
  constructor(
    private dataService: PropertiesPaymentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ searchParams }) =>
        this.dataService.load(searchParams).pipe(
          map(
            ({
              data,
            }: IResponseSuccess<{
              payments: PropertyPayment[];
              totals: PropertiesPaymentsTotals;
            }>) => {
              const { payments, totals } = data;
              return fromActions.loadSuccess({ payments, totals });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
