import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PropertiesPaymentsTotals, PropertyPayment } from '../../models';

export const featureAdapter: EntityAdapter<PropertyPayment> = createEntityAdapter<
  PropertyPayment
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PropertyPayment> {
  totals: PropertiesPaymentsTotals;
  loading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  totals: null,
  loading: false,
  error: null,
});
