import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ProductionOccupancy, StatsResponse } from '../../models';
import { StatsProductionService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ProductionOccupancyStoreEffects {
  constructor(
    private dataService: StatsProductionService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ data }) =>
        this.dataService.load(data).pipe(
          map((response: IResponseSuccess<StatsResponse>) => {
            const occupancy: any = Object.keys(
              response.data.totals.collected,
            ).reduce((dataOccupancy, key) => {
              return {
                ...dataOccupancy,
                [key]: response.data.totals.collected[key].current,
              };
            }, {});

            return fromActions.loadSuccess({
              occupancy,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
