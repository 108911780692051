import { ProductionEntities, ProductionTreatment } from '../../models';

export interface State extends ProductionEntities<ProductionTreatment> {
  loading: boolean;
  error: any;
}

export const initialState: State = {
  rows: null,
  totals: null,
  loading: false,
  error: null,
};
