import { createAction, props } from '@ngrx/store';

import { StatsRequest, StatsResponse } from '../../models';
export const loadRequest = createAction(
  '[Production Departments] Load Request',
  props<{ data: Partial<StatsRequest> }>(),
);
export const loadSuccess = createAction(
  '[Production Departments] Load Success',
  props<{ data: StatsResponse }>(),
);
export const loadFailure = createAction(
  '[Production Departments] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Production Departments] Reset State');
